.footer {
	@media (max-width:992px) {
		background: #212121;
	}

	&__body {
		background: #212121;
		padding: 0 50px;
		@media (max-width:1280px){
			padding: 0 20px;
		}
		@media (max-width:1023px){
			padding: 0;
		}
	}

	&__top {
		padding: 20px 10px;
		min-height: 80px;
		border-bottom: 1px solid #414141;
		display: flex;
		align-items: center;
		justify-content: center;
		@media (max-width:992px) {
			padding: 20px 0;
		}
	}

	&__list {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		@media (max-width:992px) {
			margin: 0 -20px;
		}
	}

	&__item {
		padding: 5px 20px;
		text-align: center;
	}

	&__link {
		font-weight: 500;
		font-size: 14px;
		line-height: 130%;
		color: #ffffff;
		transition: color 0.2s ease-in-out 0s;
		@media (any-hover: hover) {
			&:hover {
				color: rgba(255, 255, 255, 0.5);
			}
		}
	}

	&__bottom {
		padding: 25px 0;
		display: flex;
		border-bottom: 1px solid #414141;
		@media (max-width:1023px) {
			flex-direction: column;
			align-items: center;
		}
	}

	&__logo {
		margin-right: 45px;
		@media (max-width:1023px){
			margin-right: 0;
			margin-bottom: 20px;
		}
	}

	&__address {
		flex: 1 1 auto;
		display: flex;
		align-items: center;
		margin-right: 20px;
		@media (max-width:1023px){
			margin-right: 0;
			margin-bottom: 30px;
			flex-direction: column;
		}
	}

	&__map {
		width: 66px;
		display: block;
		margin-right: 20px;
		img {
			transition: filter 0.2s ease-in-out 0s;
			max-width: 100%;
		}
		@media (any-hover: hover) {
			&:hover {
				img{
					filter: drop-shadow(0 0 5px #fff);
				}
			}
		}
		@media (max-width:1023px){
			margin-right: 0;
			margin-bottom: 10px;
		}
	}

	&__address-link {
		font-size: 15px;
		line-height: 130%;
		color: #ffffff;
		max-width: 250px;
		transition: color 0.2s ease-in-out 0s;
		@media (any-hover: hover) {
			&:hover {
				color: rgba(255, 255, 255, 0.5);
			}
		}
		@media (max-width:1023px) {
			text-align: center;
			text-decoration: underline;
			max-width: none;
		}
	}

	&__contact {
		display: flex;
		align-items: center;
		.contact__phone{
			color: #fff;
			transition: color 0.2s ease-in-out 0s;
			@media (any-hover: hover) {
				&:hover {
					color: rgba(255, 255, 255, 0.5);
				}
			}
		}
		.socials__link{
			color: #6F6F6F;
			font-size: 24px;
			transition: color 0.2s ease-in-out 0s;
			@media (any-hover: hover) {
				&:hover {
					color: #fff;
				}
			}
		}
	}
}

.dev {
	padding: 25px 0;
	display: flex;
	align-items: center;
	justify-content: center;
	&__link {
		display: flex;
		align-items: center;
		@media (max-width:500px) {
			flex-direction: column;
		}
	}

	&__text {
		color: #fff;
		margin-right: 10px;
		text-decoration: underline;
		@media (any-hover: hover) {
			&:hover {
				text-decoration: none;
			}
		}
		@media (max-width:500px) {
			margin-right: 0;
			margin-bottom: 10px;
		}
	}

	&__image {
		width: 125px;
		img{
			max-width: 100%;
		}
	}
}
