.contacts {
	height: 100%;
	&__container {
		height: 100%;
	}

	&__body {
		display: flex;
		align-items: center;
		border-right: 1px solid rgba(0, 0, 0, 0.1);
		border-left: 1px solid rgba(0, 0, 0, 0.1);
		padding: 0 50px 40px 50px;
		height: 100%;
		position: relative;
		@media (max-width: 1280px) {
			padding: 0 20px 30px 20px;
		}
		@media (max-width: 1023px) {
			padding: 0 0 30px 0;
			border: 0;
		}
		@media (max-width:767px) {
			padding: 0;
		}
		&:after,
		&:before {
			content: "";
			display: block;
			position: absolute;
			top: -100%;
			background: rgba(0, 0, 0, 0.1);
			width: 1px;
			height: 100%;
			@media (max-width: 1023px) {
				display: none;
			}
		}
		&:after {
			left: -1px;
		}
		&:before {
			right: -1px;
		}
		@media (max-width:767px) {
			align-items: stretch;
			flex-direction: column;
		}
	}

	&__info {
		margin-right: 150px;
		@media (max-width:992px) {
			margin-right: 20px;
		}
		@media (max-width:767px) {
			margin-right: 0;
		}
	}

	&__subtitle {
		margin-bottom: 40px;
		@media (max-width:767px) {
			margin-bottom: 20px;
		}
	}

	&__map {
		flex: 1 1 auto;
		border-radius: 8px;
		overflow: hidden;
		height: 390px;
		iframe {
			height: 100%;
			width: 100%;
		}
		@media (max-width:767px) {
			height: 250px;
			border-radius: 0;
			margin: 0 -20px;
		}
	}
}
.block {
	margin-bottom: 30px;
	max-width: 350px;
	display: flex;
	align-items: center;
	@media (max-width:767px) {
		margin-bottom: 20px;
		max-width: none;
	}
	&__icon {
		font-size: 28px;
		color: #ef3f3f;
		flex: 0 0 60px;
		width: 60px;
		height: 60px;
		background: #ffffff;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 16px;
	}

	&__caption {
		font-weight: bold;
		font-size: 15px;
		line-height: 155%;
		color: #212121;
	}

	&__text {
		font-size: 15px;
		line-height: 155%;
		a{
			transition: color 0.3s ease 0s;
			color: #212121;
			@media (any-hover: hover) {
				&:hover {
					color: rgba(0,0,0,0.5);
				}
			}
		}
	}
}
