.video {
	&__body {
		border-right: 1px solid rgba(0,0,0,0.1);
		border-left: 1px solid rgba(0,0,0,0.1);
		padding: 0 50px 40px 50px;
		@media (max-width: 1280px) {
			padding: 0 20px 30px 20px;
		}
		@media (max-width: 1023px) {
			padding: 0 0 30px 0;
			border: 0;
		}
		iframe{
			border-radius: 8px;
			overflow: hidden;
			width: 100%;
			height: 400px;
			@media (max-width:767px) {
				height: 300px;
			}
			@media (max-width:500px) {
				height: 200px;
			}
		}
	}
}