@font-face {
  font-family: 'dynamo';
  src:
    url('../fonts/dynamo.ttf?jtotoz') format('truetype'),
    url('../fonts/dynamo.woff?jtotoz') format('woff'),
    url('../fonts/dynamo.svg?jtotoz#dynamo') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="_icon-"]::before, [class*=" _icon-"]::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'dynamo' !important;
  //speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

._icon-email:before {
  content: "\e900";
}
._icon-fb:before {
  content: "\e901";
}
._icon-insta:before {
  content: "\e902";
}
._icon-news:before {
  content: "\e903";
}
._icon-phone:before {
  content: "\e904";
}
._icon-photos:before {
  content: "\e905";
}
._icon-pin:before {
  content: "\e906";
}
._icon-players:before {
  content: "\e907";
}
._icon-tg:before {
  content: "\e908";
}
._icon-vk:before {
  content: "\e909";
}
