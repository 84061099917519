.dude {
	height: 100%;

	&__container {
		height: 100%;
	}

	&__body {
		height: 100%;
		position: relative;
		border-right: 1px solid rgba(0, 0, 0, 0.1);
		border-left: 1px solid rgba(0, 0, 0, 0.1);
		padding: 0 50px 40px 50px;
		@media (max-width: 1280px) {
			padding: 0 20px 30px 20px;
		}
		@media (max-width: 1023px) {
			padding: 0 0 30px 0;
			border: 0;
		}
		&:after,
		&:before {
			content: "";
			display: block;
			position: absolute;
			top: -100%;
			background: rgba(0, 0, 0, 0.1);
			width: 1px;
			height: 100%;
			@media (max-width: 1023px) {
				display: none;
			}
		}
		&:after {
			left: -1px;
		}
		&:before {
			right: -1px;
		}
	}

	&__title {
		margin-bottom: 26px;
	}
	&__content {
		display: flex;
		@media (max-width:767px) {
			flex-direction: column;
		}
	}

	&__image {
		flex: 0 0 300px;
		border-radius: 8px;
		overflow: hidden;
		position: relative;
		max-width: 300px;
		width: 100%;
		height: 450px;
		margin-right: 40px;
		img {
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
		@media (max-width:767px) {
			margin-right: 0;
			flex: none;
			align-self: center;
			margin-bottom: 20px;
			height: 400px;
		}
	}

	&__field {
		margin-bottom: 30px;
		&:last-child{
			margin-bottom: 0;
		}
		@media (max-width:767px) {
			margin-bottom: 20px;
		}
	}

	&__caption {
		font-weight: 500;
		font-size: 22px;
		line-height: 130%;
		margin-bottom: 10px;
	}

	&__text {
		font-size: 18px;
		line-height: 130%;
	}
}
