.team {
	&__body {
		position: relative;
		border-right: 1px solid rgba(0, 0, 0, 0.1);
		border-left: 1px solid rgba(0, 0, 0, 0.1);
		padding: 0 50px 40px 50px;
		@media (max-width: 1280px) {
			padding: 0 20px 30px 20px;
		}
		@media (max-width: 1023px) {
			padding: 0 0 30px 0;
			border: 0;
		}
		&:after,
		&:before {
			content: "";
			display: block;
			position: absolute;
			top: -100%;
			background: rgba(0, 0, 0, 0.1);
			width: 1px;
			height: 100%;
			@media (max-width: 1023px) {
				display: none;
			}
		}
		&:after {
			left: -1px;
		}
		&:before {
			right: -1px;
		}
	}

	&__title {
		margin-bottom: 26px;
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -10px;
	}
}
.guy {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	flex: 0 0 25%;
	padding: 10px;
	@media (max-width: 992px) {
		flex: 0 0 33.333%;
	}
	@media (max-width: 767px) {
		flex: 0 0 50%;
	}
	@media (max-width: 500px) {
		flex: 0 0 100%;
	}
	@media (any-hover: hover) {
		&:hover {
			.guy__link{
				box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
				transform: scale3d(0.99,0.99,0.99);
			}
		}
	}
	&__link {
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
		border-radius: 0 0 8px 8px;
		display: inline-block;
		height: 100%;
		width: 100%;
		padding: 20px;
		transform: scale3d(1,1,1);
		transition: transform 0.2s ease-in-out 0s, box-shadow 0.2s ease-in-out 0s;
	}

	&__image {
		border-radius: 50%;
		overflow: hidden;
		position: relative;
		width: 122px;
		height: 122px;
		display: inline-block;
		margin-bottom: 20px;
		img {
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
	}

	&__name {
		font-weight: 500;
		font-size: 15px;
		line-height: 130%;
		color: #212121;
		margin-bottom: 8px;
	}

	&__position {
		font-size: 13px;
		line-height: 130%;
		color: #bbbbbb;
		margin-bottom: 12px;
	}
}
