.article {
	&__body {
		position: relative;
		border-right: 1px solid rgba(0, 0, 0, 0.1);
		border-left: 1px solid rgba(0, 0, 0, 0.1);
		padding: 0 50px 40px 50px;
		@media (max-width: 1280px) {
			padding: 0 20px 30px 20px;
		}
		@media (max-width: 1023px) {
			padding: 0 0 30px 0;
			border: 0;
		}
		&:after,
		&:before {
			content: "";
			display: block;
			position: absolute;
			top: -100%;
			background: rgba(0, 0, 0, 0.1);
			width: 1px;
			height: 100%;
			@media (max-width: 1023px) {
				display: none;
			}
		}
		&:after {
			left: -1px;
		}
		&:before {
			right: -1px;
		}
		img {
			max-width: 100% !important;
			display: inline-block !important;
			margin-bottom: 40px !important;
			@media (max-width:1023px) {
				margin-bottom: 30px !important;
			}
			@media (max-width:767px) {
				margin-bottom: 20px !important;
			}
		}
		iframe {
			display: block;
			position: relative;
			width: 100% !important;
			height: 400px !important;
			margin-bottom: 40px !important;
			@media (max-width: 1023px) {
				height: 300px !important;
				margin-bottom: 30px !important;
			}
			@media (max-width: 992px) {
				height: 200px !important;
				margin-bottom: 20px !important;
			}
		}
		p {
			font-size: 18px !important;
			line-height: 130% !important;
			color: #212121 !important;
			margin-bottom: 10px !important;
			@media (max-width:1023px) {
				font-size: 16px !important;
			}
			@media (max-width:767px) {
				font-size: 14px !important;
			}
		}
		h1{
			font-weight: 700 !important;
			line-height: 130% !important;
			color: #212121 !important;
			font-size: 36px !important;
			margin-bottom: 40px !important;
			@media (max-width:1023px) {
				margin-bottom: 30px !important;
				font-size: 26px !important;
			}
			@media (max-width:767px) {
				margin-bottom: 20px !important;
				font-size: 22px !important;
			}
		}
		h2{
			font-weight: 700 !important;
			line-height: 130% !important;
			color: #212121 !important;
			font-size: 32px !important;
			margin-bottom: 30px !important;
			@media (max-width:1023px) {
				font-size: 22px !important;
				margin-bottom: 20px !important;
			}
			@media (max-width:767px) {
				font-size: 20px !important;
			}
		}
		h3{
			margin-bottom: 10px !important;
			font-weight: 700 !important;
			line-height: 130% !important;
			color: #212121 !important;
			font-size: 30px !important;
			margin-bottom: 10px !important;
			@media (max-width:1023px) {
				font-size: 20px !important;
			}
			@media (max-width:767px) {
				font-size: 18px !important;
			}
		}
		h4{
			margin-bottom: 10px !important;
			font-weight: 700 !important;
			line-height: 130% !important;
			color: #212121 !important;
			font-size: 28px !important;
			@media (max-width:1023px) {
				font-size: 18px !important;
			}
			@media (max-width:767px) {
				font-size: 16px !important;
			}
		}
		h5{
			font-weight: 500 !important;
			line-height: 130% !important;
			color: #212121 !important;
			font-size: 22px !important;
			margin-bottom: 10px !important;
			@media (max-width:1023px) {
				font-size: 16px !important;
			}
		}
		h6{
			margin-bottom: 10px !important;
			font-weight: 500 !important;
			line-height: 130% !important;
			color: #212121 !important;
			font-size: 20px !important;
			@media (max-width:1023px) {
				font-size: 16px !important;
			}
		}
	}
}
