.banner {
	&__body {
		border-right: 1px solid rgba(0,0,0,0.1);
		border-left: 1px solid rgba(0,0,0,0.1);
		padding: 0 50px 40px 50px;
		@media (max-width: 1280px) {
			padding: 0 20px 30px 20px;
		}
		@media (max-width: 1023px) {
			padding: 0 0 30px 0;
			border: 0;
		}
	}

	&__content {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		border-radius: 8px;
		font-weight: 500;
		font-size: 18px;
		line-height: 130%;
		color: #ffffff;
		overflow: hidden;
		position: relative;
		padding: 20px 20px 20% 20px;
		min-height: 150px;
		img {
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			height: 100%;
			width: 100%;
		}

	}
}
