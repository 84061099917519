.photos {
	&__body {
		position: relative;
		border-right: 1px solid rgba(0, 0, 0, 0.1);
		border-left: 1px solid rgba(0, 0, 0, 0.1);
		padding: 0 50px 40px 50px;
		@media (max-width: 1280px) {
			padding: 0 20px 30px 20px;
		}
		@media (max-width: 1023px) {
			padding: 0 0 30px 0;
			border: 0;
		}
		&:after,
		&:before {
			content: "";
			display: block;
			position: absolute;
			top: -100%;
			background: rgba(0, 0, 0, 0.1);
			width: 1px;
			height: 100%;
			@media (max-width: 1023px) {
				display: none;
			}
		}
		&:after {
			left: -1px;
		}
		&:before {
			right: -1px;
		}
	}

	&__title{
		margin-bottom: 26px;
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -10px;
		padding-bottom: 40px;
		@media (max-width:992px) {
			padding-bottom: 20px;
		}
	}
}
.photo {
	flex: 0 0 25%;
	@media (max-width: 992px) {
		flex: 0 0 33.333%;
	}
	@media (max-width: 767px) {
		flex: 0 0 50%;
	}
	@media (max-width: 500px) {
		flex: 0 0 100%;
	}
	&__link {
		padding: 10px;
		display: block;
		@media (any-hover: hover) {
			&:hover {
				.photo__image {
					&:after {
						background: rgba(0, 0, 0, 0.3);
					}
					img {
						transform: scale3d(1.03, 1.03, 1.03);
					}
				}
			}
		}
	}

	&__image {
		border-radius: 8px;
		overflow: hidden;
		position: relative;
		padding: 70% 0 0 0;
		img {
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			height: 100%;
			width: 100%;
			transition: transform 0.3s ease-in-out 0s;
			transform: scale3d(1, 1, 1);
		}
		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, 0.6);
			border-radius: 8px;
			transition: background-color 0.3s ease 0s;
		}
	}

	&__date {
		font-size: 13px;
		line-height: 130%;
		color: #ffffff;
		position: absolute;
		bottom: 90px;
		left: 20px;
		z-index: 1;
		padding-right: 20px;
	}

	&__title {
		position: absolute;
		bottom: 20px;
		left: 20px;
		z-index: 1;
		font-weight: 500;
		font-size: 16px;
		line-height: 130%;
		color: #ffffff;
		word-break: break-word;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		padding-right: 20px;
		overflow: hidden;
		height: 62px;
	}
}
.pagination {
	display: flex;
	justify-content: center;
	&__body {
		display: flex;
		align-items: center;
	}
	&__arrow {
		display: block;
		width: 36px;
		height: 36px;
		border-radius: 50%;
		border: 1px solid #e7e7e7;
		transition: all 0.3s ease-in-out 0s;
		@media (any-hover: hover) {
			&:hover {
				border: 1px solid #212121;
			}
		}
	}

	&__arrow--back {
		background: url("../images/arrow-left.svg") center no-repeat, #fff;
	}

	&__list {
		margin: 0 10px;
	}

	&__page {
		font-weight: 500;
		font-size: 16px;
		line-height: 130%;
		text-align: center;
		color: #212121;
		padding: 10px;
		transition: color 0.3s ease-in-out 0s;
		@media (any-hover: hover) {
			&:hover {
				color: rgba(33, 33, 33, 0.5);
			}
		}
	}
	
	&__page--active{
		color: rgba(33, 33, 33, 0.5);
	}

	&__arrow--next {
		background: url("../images/arrow-right.svg") center no-repeat, #fff;
	}
}
