.pic {

	&__body {
		position: relative;
		border-right: 1px solid rgba(0, 0, 0, 0.1);
		border-left: 1px solid rgba(0, 0, 0, 0.1);
		padding: 0 50px 40px 50px;
		@media (max-width: 1280px) {
			padding: 0 20px 30px 20px;
		}
		@media (max-width: 1023px) {
			padding: 0 0 30px 0;
			border: 0;
		}
		&:after,
		&:before {
			content: "";
			display: block;
			position: absolute;
			top: -100%;
			background: rgba(0, 0, 0, 0.1);
			width: 1px;
			height: 100%;
			@media (max-width: 1023px) {
				display: none;
			}
		}
		&:after {
			left: -1px;
		}
		&:before {
			right: -1px;
		}
	}

	&__title{
		margin-bottom: 26px;
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -10px;
		padding-bottom: 40px;
		@media (max-width: 992px) {
			padding-bottom: 20px;
		}
	}

	&__item {
		flex: 0 0 25%;
		@media (max-width: 992px) {
			flex: 0 0 33.333%;
		}
		@media (max-width: 767px) {
			flex: 0 0 50%;
		}
		@media (max-width: 500px) {
			flex: 0 0 100%;
		}
	}

	&__link {
		margin: 10px;
		display: block;
		border-radius: 8px;
		overflow: hidden;
		position: relative;
		padding: 70% 0 0 0;
		img {
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			height: 100%;
			width: 100%;
			transition: transform 0.3s ease-in-out 0s;
			transform: scale3d(1, 1, 1);
		}
		@media (any-hover: hover) {
			&:hover {
				img {
					transform: scale3d(1.03, 1.03, 1.03);
				}
			}
		}
	}
}
