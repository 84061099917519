.header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 100;
	background: #fff;
	transition: all 0.3s ease-in-out 0s;
	&__bg{
		overflow: hidden;
		position: relative;
		padding: 11% 0 0 0;
		img {
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
		@media (max-width:1023px) {
			.menu{
				margin: 20px;
			}
		}
		@media (max-width:992px) {
			.menu{
				display: none;
			}
		}
	}
	&__body {
		border-right: 1px solid rgba(0,0,0,0.1);
		border-left: 1px solid rgba(0,0,0,0.1);
		padding: 10px 50px 5px 50px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		&::after{
			content: '';
			position: absolute;
			bottom: 0;
			left: 50%;
			width: calc(100% - 100px);
			background: #e9e9e9;
			height: 1px;
			display: block;
			transform: translate(-50%,0);
			@media (max-width:1280px) {
				width: calc(100% - 40px);
			}
			@media (max-width:1023px) {
				width: 100%;
			}
		}
		@media (max-width:1280px){
			padding: 10px 20px 5px 20px;
		}
		@media (max-width:1023px){
			padding: 10px 0px 5px 0px;
			border: 0;
		}
	}

/* 	&__logo {
		margin-right: 20px;
		white-space: nowrap;
		@media (max-width:1280px) {
			margin-right: 0;
		}
		@media (max-width:992px) {
			flex: 1 1 auto;
			position: relative;
			z-index: 5;
		}
	}
 */
	&__phone {
		display: none;
		@media (max-width:992px) {
			display: flex;
			align-items: center;
			margin-right: 30px;
			span{
				font-weight: 500;
				font-size: 18px;
				line-height: 130%;
				color: #212121;
			}
		}
		@media (max-width:500px) {
			span{
				display: none;
			}
		}
		@media (max-width:375px) {
			margin-right: 0;
		}
	}

	&__phone-image{
		width: 28px;
		margin-right: 10px;
		img{
			max-width: 100%;
		}
	}

	&__burger {
		display: none;
		@media (max-width:992px) {
			display: block;
			position: relative;
			top: 0;
			right: -4px;
			height: 40px;
			width: 40px;
			z-index: 5;
			&:before,&:after{
				content: '';
				position: absolute;
				right: 4px;
				background: #212121;
				height: 2px;
				width: 30px;
				transition: transform 0.3s ease-in-out 0s, background-color 0.3s ease-in-out 0s;
			}
			&:before{
				top: 13px;
			}
			&:after{
				bottom: 13px;
			}
			&.header__burger--active{
				&:before{
					transform: rotate(-45deg) translate(0,8px);
					background: #fff;
				}
				&:after{
					transform: rotate(45deg) translate(0,-8px);
					background: #fff;
				}
			}
		}
	}
	&__mobile-group{
		.menu{
			display: none;
		}
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 1 1 auto;
		@media (max-width:992px) {
			position: fixed;
			top: 0;
			left: 0;
			z-index: 1;
			flex-direction: column;
			padding: 100px 20px 50px 20px;
			background: rgba(33, 33, 33, 0.95);
			height: 100%;
			width: 100%;
			overflow: auto;
			transform: translate3d(0,-100%,0);
			transition: transform 0.3s ease-in-out 0s;
			&.header__mobile-group--active{
				transform: translate3d(0,0,0);
			}
			.menu{
				display: block;
			}
		}
	}
}
.menu {
	flex: 1 1 auto;
	margin: 20px;
	position: relative;
	&__list {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		@media (max-width:992px) {
			flex-direction: column;
		}
	}

	&__item {
		@media (max-width:992px) {
			margin-bottom: 20px;
		}
	}

	&__link {
		padding: 10px 5px;
		font-weight: 500;
		font-size: 14px;
		line-height: 130%;
		color: #fff;
		&.menu__link--active{
			color: rgba(255, 255, 255, 0.7);
			@media (max-width:992px) {
				color: #fff;
				text-decoration: underline;
			}
		}
		transition: color 0.3s ease-in-out 0s;
		@media (any-hover: hover) {
			&:hover {
				color: rgba(255, 255, 255, 0.7);
			}
		}
		@media (max-width:1023px) {
			padding: 10px 5px;
		}
		@media (max-width:992px) {
			font-size: 22px;
			color: #fff;
		}
	}
	@media (max-width:1023px) {
		margin: 0 10px;
	}
}
.scroll-down header{
	transform: translate3d(0, -100%, 0);
}