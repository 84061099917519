.news {

	&__body {
		position: relative;
		border-right: 1px solid rgba(0, 0, 0, 0.1);
		border-left: 1px solid rgba(0, 0, 0, 0.1);
		padding: 0 50px 40px 50px;
		@media (max-width: 1280px) {
			padding: 0 20px 30px 20px;
		}
		@media (max-width: 1023px) {
			padding: 0 0 30px 0;
			border: 0;
		}
		&:after,
		&:before {
			content: "";
			display: block;
			position: absolute;
			top: -100%;
			background: rgba(0, 0, 0, 0.1);
			width: 1px;
			height: 100%;
			@media (max-width: 1023px) {
				display: none;
			}
		}
		&:after {
			left: -1px;
		}
		&:before {
			right: -1px;
		}
	}

	&__title {
		margin-bottom: 26px;
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -10px;
		padding-bottom: 40px;
		@media (max-width: 992px) {
			padding-bottom: 20px;
		}
	}
}
.piece {
	flex: 0 0 25%;
	display: flex;
	flex-direction: column;
	align-self: stretch;
	padding: 10px;
	transition: transform 0.3s ease 0s;
	transform: scale3d(1, 1, 1);
	@media (max-width: 992px) {
		flex: 0 0 33.333%;
	}
	@media (max-width: 767px) {
		flex: 0 0 50%;
	}
	@media (max-width: 500px) {
		flex: 0 0 100%;
	}
	@media (any-hover: hover) {
		&:hover {
			transform: scale3d(0.99,0.99,0.99);
			.piece__body {
				box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
			}
		}
	}
	&__link {
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
	}
	&__image {
		overflow: hidden;
		position: relative;
		padding: 0 0 60% 0;
		img {
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
	}

	&__body {
		padding: 20px;
		transition: box-shadow 0.2s ease 0s;
		border: 1px solid rgba(0, 0, 0, 0.1);
		border-top: 0;
		border-radius: 0 0 8px 8px;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
		flex: 1 1 auto;
	}

	&__date {
		font-size: 13px;
		line-height: 130%;
		color: #212121;
		margin-bottom: 8px;
	}

	&__title {
		font-weight: 500;
		font-size: 16px;
		line-height: 130%;
		color: #212121;
		margin-bottom: 12px;
		word-break: break-word;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		height: 42px;
	}

	&__text {
		line-height: 130%;
		color: #212121;
		word-break: break-word;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 6;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
}
