.gallery {
	&__body {
		border-right: 1px solid rgba(0, 0, 0, 0.1);
		border-left: 1px solid rgba(0, 0, 0, 0.1);
		padding: 0 50px 40px 50px;
		@media (max-width: 1280px) {
			padding: 0 20px 30px 20px;
		}
		@media (max-width: 1023px) {
			padding: 0 0 30px 0;
			border: 0;
		}
	}

	&__title {
		margin-bottom: 26px;
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -15px;
		@media (max-width: 992px) {
			margin: -10px;
		}
		@media (max-width:500px) {
			margin: -5px;
		}
	}

	&__item {
		flex: 0 0 25%;
		padding: 0 15px;
		@media (max-width: 992px) {
			padding: 10px;
			flex: 0 0 50%;
		}
		@media (max-width:500px) {
			padding: 5px;
			flex: 0 0 100%;
		}
	}

	&__link {
		border-radius: 8px;
		overflow: hidden;
		position: relative;
		padding: 0 0 61% 0;
		display: block;
		img {
			transition: transform 0.3s ease-in-out 0s;
			width: 100%;
			height: 100%;
			object-fit: cover;
			position: absolute;
			top: 0;
			left: 0;
			transform: scale3d(1, 1, 1);
		}
		@media (any-hover: hover) {
			&:hover {
				img{
					transform: scale3d(1.03, 1.03, 1.03);
				}
			}
		}
	}
}
