// Общее
@import "./includes/normalize";
@import "./includes/dynamo";

// Общие селекторы
html {
	overflow-x: hidden;
	overflow-y: scroll;
}
body {
	font-family: "Roboto", sans-serif !important;
	color: #212121;
	@media (max-width: 992px) {
		&.lock {
			overflow: hidden;
		}
	}
}
.wrapper {
	height: 100%;
	display: flex;
	flex-direction: column;
}
main {
	flex: 1 1 auto;
	padding-top: 260px;
	@media (max-width: 992px) {
		padding-top: 180px;
	}
	@media (max-width:767px) {
		padding-top: 150px;
	}
	@media (max-width:500px) {
		padding-top: 120px;
	}
}
.container {
	max-width: 1280px;
	margin: 0 auto;
	padding: 0 20px;
}
.title {
	font-weight: 500;
	font-size: 22px;
	line-height: 130%;
	color: #212121;
	display: flex;
	align-items: center;
	&__icon {
		color: #d6d6d6;
		background: #ffffff;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
		border-radius: 50%;
		height: 50px;
		width: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 20px;
	}
}
.subtitle {
	font-weight: 500;
	font-size: 22px;
	line-height: 130%;
	color: #212121;
}
.link {
	font-size: 13px;
	line-height: 130%;
	text-decoration-line: underline;
	color: #414ba0;
	@media (any-hover: hover) {
		&:hover {
			text-decoration: none;
		}
	}
}

// Компоненты
.socials {
	display: flex;
	align-items: center;
	margin: 0 -4px;
	&__item {
		width: 32px;
		height: 32px;
		.socials__link {
			@media (any-hover: hover) {
				&:hover {
					img {
						filter: drop-shadow(0 0 3px #fff);
					}
				}
			}
		}
		@media (any-hover: hover) {
			&:hover {
				.socials__link {
					img {
						box-shadow: 0 0 5px rgba(33, 33, 33, 0.5);
					}
				}
			}
		}
	}

	&__link {
		display: inline-block;
		padding: 4px;
		height: 100%;
		width: 100%;
		img {
			transition: filter 0.2s ease-in-out 0s;
			width: 24px;
			height: 24px;
			border-radius: 50%;
			overflow: hidden;
			max-width: 100%;
			box-shadow: 0 0 0 rgba(33, 33, 33, 0.5);
		}
	}
}
.logo {
	display: flex;
	align-items: center;
	@media (any-hover: hover) {
		&:hover {
			.logo__descriptor {
				color: rgba(33, 33, 33, 0.5);
			}
			.logo__descriptor--footer {
				color: rgba(255, 255, 255, 0.5);
			}
		}
	}
	&__image {
		width: 40px;
		margin-right: 14px;
		img {
			max-width: 100%;
		}
		@media (max-width: 992px) {
			width: 26px;
		}
	}

	&__descriptor {
		transition: color 0.3s ease-in-out 0s;
		font-weight: 500;
		font-size: 18px;
		line-height: 130%;
		color: #212121;
		&.logo__descriptor--footer {
			color: #fff;
		}
		@media (max-width: 992px) {
			&.logo__descriptor--active {
				color: #fff;
			}
		}
	}
}
.contact {
	&__body {
		display: flex;
		align-items: center;
		@media (max-width: 500px) {
			flex-direction: column;
		}
	}

	&__phone {
		font-weight: 500;
		font-size: 18px;
		line-height: 130%;
		color: inherit;
		margin-right: 20px;
		white-space: nowrap;
		transition: color 0.3s ease-in-out 0s;
		@media (any-hover: hover) {
			&:hover {
				color: rgba(33, 33, 33, 0.5);
			}
		}
		@media (max-width: 992px) {
			font-size: 22px;
			color: #fff;
		}
		@media (max-width: 500px) {
			margin-right: 0;
			margin-bottom: 10px;
		}
	}
}

// Блоки
@import "./includes/header";
@import "./includes/mainscreen";
@import "./includes/footer";
@import "./includes/banner";
@import "./includes/gallery";
@import "./includes/video";
@import "./includes/players";
@import "./includes/contacts";
@import "./includes/schedule";
@import "./includes/gallery-page";
@import "./includes/pictures";
@import "./includes/team";
@import "./includes/player-page";
@import "./includes/news";
@import "./includes/article";
