.players {
	&__body {
		position: relative;
		border-right: 1px solid rgba(0, 0, 0, 0.1);
		border-left: 1px solid rgba(0, 0, 0, 0.1);
		padding: 0 50px 40px 50px;
		@media (max-width: 1280px) {
			padding: 0 20px 30px 20px;
		}
		@media (max-width: 1023px) {
			padding: 0 0 30px 0;
			border: 0;
		}
	}

	&__title {
		margin-bottom: 26px;
	}

	&__button-prev {
		position: absolute;
		top: 10px;
		right: 110px;
		width: 50px;
		height: 30px;
		border-radius: 8px;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
		cursor: pointer;
		background: url("../images/arrow-left.svg") center no-repeat, #fff;
		transition: all 0.3s ease-in-out 0s;
		&.swiper-button-disabled {
			opacity: 0.35;
			cursor: auto;
			pointer-events: none;
		}
		&:after {
			font-size: 0;
		}
		@media (any-hover: hover) {
			&:hover {
				transform: scale(0.97);
				box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
			}
		}
		@media (max-width: 1280px) {
			right: 80px;
		}
		@media (max-width: 1023px) {
			right: 60px;
		}
	}

	&__button-next {
		cursor: pointer;
		position: absolute;
		top: 10px;
		right: 50px;
		width: 50px;
		height: 30px;
		border-radius: 8px;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
		background: url("../images/arrow-right.svg") center no-repeat, #fff;
		transition: all 0.3s ease-in-out 0s;
		&.swiper-button-disabled {
			opacity: 0.35;
			cursor: auto;
			pointer-events: none;
		}
		&:after {
			font-size: 0;
		}
		@media (any-hover: hover) {
			&:hover {
				transform: scale(0.97);
				box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
			}
		}
		@media (max-width: 1280px) {
			right: 20px;
		}
		@media (max-width: 1023px) {
			right: 0;
		}
	}
}
.players-slider {
	margin: 0 -13px;
	overflow: hidden;
	@media (max-width: 767px) {
		margin: 0 0 0 -5px;
	}
	&__wrapper {
		padding-bottom: 20px;
	}

	&__slide {
		padding: 0 13px;
		@media (max-width: 767px) {
			padding: 0 5px;
		}
	}

	&__wrap {
		border: 1px solid rgba(0, 0, 0, 0.1);
		border-top: 0;
		padding: 28px;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		border-radius: 0 0 8px 8px;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
		@media (max-width: 992px) {
			padding: 20px;
		}
	}

	&__image {
		display: block;
		width: 122px;
		height: 122px;
		border-radius: 50%;
		overflow: hidden;
		position: relative;
		margin-bottom: 20px;
		img {
			transition: transform 0.3s ease-in-out 0s;
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			width: 100%;
			height: 100%;
			transform: scale3d(1, 1, 1);
		}
		@media (any-hover: hover) {
			&:hover {
				img {
					transform: scale3d(1.03, 1.03, 1.03);
				}
			}
		}
	}

	&__name {
		font-weight: 500;
		font-size: 15px;
		line-height: 130%;
		color: #212121;
		margin-bottom: 8px;

		word-break: break-word;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
		height: 58px;
	}

	&__position {
		font-size: 13px;
		line-height: 130%;
		color: #bbbbbb;
		margin-bottom: 12px;
		word-break: break-word;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		height: 34px;
	}
}
