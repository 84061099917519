.schedule {
	height: 100%;

	&__container {
		height: 100%;
	}

	&__body {
		height: 100%;
		border-right: 1px solid rgba(0, 0, 0, 0.1);
		border-left: 1px solid rgba(0, 0, 0, 0.1);
		padding: 0 50px 40px 50px;
		height: 100%;
		position: relative;
		&:after,
		&:before {
			content: "";
			display: block;
			position: absolute;
			top: -100%;
			background: rgba(0, 0, 0, 0.1);
			width: 1px;
			height: 100%;
			@media (max-width: 1023px) {
				display: none;
			}
		}
		&:after {
			left: -1px;
		}
		&:before {
			right: -1px;
		}
		@media (max-width: 1280px) {
			padding: 0 20px 30px 20px;
		}
		@media (max-width: 1023px) {
			padding: 0 0 30px 0;
			border: 0;
		}
	}

	&__topbar {
		margin-bottom: 20px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		@media (max-width:500px) {
			flex-direction: column;
			align-items: stretch;
		}
	}

	&__subtitle {
		margin-right: 20px;
		@media (max-width:500px) {
			margin-right: 0;
			margin-bottom: 10px;
		}
	}

	&__select {
		font-size: 16px;
		background: #ffffff;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
		border-radius: 8px;
		padding: 10px 30px 10px 16px;
		height: 40px;
		cursor: pointer;
		width: 230px;
		appearance: none;
		background: url('../images/select-arrow.svg') 92% center no-repeat;
		@media (max-width:500px) {
			width: 100%;
		}
	}
}
.row {
	padding: 16px 30px;
	margin-bottom: 18px;
	display: flex;
	align-items: center;
	background: #ffffff;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
	transform: scale3d(1, 1, 1);
	border-radius: 8px;
	transition: box-shadow 0.3s ease-in-out 0s, transform 0.3s ease-in-out 0s;
	@media (any-hover: hover) {
		&:hover {
			transform: scale3d(0.99, 0.99, 0.99);
			box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
		}
	}
	@media (max-width:767px) {
		flex-direction: column;
		padding: 16px;
	}

	&__opposition {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		@media (max-width:767px) {
			flex-direction: column;
		}
	}

	&__player {
		flex: 0 0 58px;
		width: 58px;
		height: 58px;
		border-radius: 50%;
		background: #ffffff;
		border: 1px solid #e7e7e7;
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			width: 38px;
		}
		@media (max-width:500px) {
			width: 50px;
			height: 50px;
			flex: 0 0 50px;
			img {
				width: 30px;
			}
		}
	}

	&__event {
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0 18px;
		flex: 0 0 20%;
	}

	&__tournament {
		font-size: 14px;
		line-height: 130%;
		color: #c7c7c7;
		margin-bottom: 6px;
		@media (max-width:767px) {
			margin-top: 10px;
		}
	}

	&__name {
		flex: 0 0 30%;
		font-weight: 500;
		font-size: 16px;
		line-height: 130%;
		color: #212121;
		margin: 10px;
		text-align: center;
		@media (max-width:500px) {
			font-size: 14px;
		}
	}

	&__score {
		display: flex;
		align-items: center;
		justify-content: center;
		white-space: nowrap;
		padding: 8px 16px;
		text-align: center;
		background: #ededed;
		border-radius: 8px;
		font-weight: 500;
		color: #212121;
		max-width: 120px;
		@media (max-width:767px) {
			font-size: 16px;
			margin-bottom: 10px;
		}
	}
}
