.mainscreen {

	&__body {
		display: flex;
		min-width: 0;
		padding: 0 50px 40px 50px;
		border-right: 1px solid rgba(0, 0, 0, 0.1);
		border-left: 1px solid rgba(0, 0, 0, 0.1);
		position: relative;
		@media (max-width: 1280px) {
			padding: 0 20px 30px 20px;
		}
		@media (max-width: 1023px) {
			padding: 0 0 30px 0;
			border: 0;
		}
		@media (max-width: 992px) {
			padding: 0 0 20px 0;
			flex-direction: column;
		}
		&:after,
		&:before {
			content: "";
			display: block;
			position: absolute;
			top: -100%;
			background: rgba(0, 0, 0, 0.1);
			width: 1px;
			height: 100%;
			@media (max-width:1023px) {
				display: none;
			}
		}
		&:after {
			left: -1px;
		}
		&:before {
			right: -1px;
		}
	}

	&__left {
		display: flex;
		min-width: 0;
		flex: 1 1 auto;
		padding-right: 30px;
		@media (max-width: 1023px) {
			padding-right: 20px;
		}
		@media (max-width: 992px) {
			padding-right: 0;
		}
	}

	&__news {
		min-width: 0;
	}

	&__topbar {
		display: flex;
		align-items: center;
		margin-bottom: 26px;
		position: relative;
	}

	&__title {
		margin-right: 20px;
	}

	&__slider {
		margin-bottom: 30px;
		@media (max-width: 1023px) {
			margin-bottom: 20px;
		}
	}

	&__right {
		flex: 0 0 360px;
		@media (max-width: 992px) {
			flex: none;
		}
	}

	&__events {
		padding: 20px;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
		border-radius: 8px;
		margin-bottom: 20px;
		@media (max-width: 767px) {
			padding: 20px 10px;
		}
	}
}
.slider {
	overflow: hidden;
	&__slide {
		position: relative;
		padding: 36.5% 0 0 0;
		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, 0.4);
			border-radius: 8px;
			transition: background-color 0.3s ease 0s;
		}
		@media (any-hover: hover) {
			&:hover {
				&:after {
					background: rgba(0, 0, 0, 0.3);
				}
			}
		}
		@media (max-width: 767px) {
			padding: 30% 0 0 0;
		}
	}

	&__image {
		text-align: center;
		overflow: hidden;
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		border-radius: 8px;
		img {
			transition: transform 0.5s ease 0s;
			width: 100%;
			height: 100%;
			object-fit: cover;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	&__date {
		padding: 15px 30px;
		position: relative;
		font-size: 13px;
		line-height: 130%;
		color: #ffffff;
		z-index: 1;
	}

	&__text {
		padding: 0 30px 0px 30px;
		position: relative;
		font-weight: 500;
		font-size: 18px;
		line-height: 130%;
		color: #ffffff;
		z-index: 1;
		@media (any-hover: hover) {
			&:hover {
				text-decoration: underline;
			}
		}
		word-break: break-word;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		height: 48px;
		margin-bottom: 24px;
	}

	&__button-prev {
		top: 30px;
		left: calc(100% - 110px);
		width: 50px;
		height: 30px;
		border-radius: 8px;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
		cursor: pointer;
		background: url("../images/arrow-left.svg") center no-repeat, #fff;
		transition: all 0.3s ease-in-out 0s;
		&:after {
			font-size: 0;
		}
		@media (any-hover: hover) {
			&:hover {
				transform: scale(0.97);
				box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
			}
		}
		@media (max-width: 500px) {
			top: 150px;
			left: -10px;
		}
	}

	&__button-next {
		top: 30px;
		right: 0;
		width: 50px;
		height: 30px;
		border-radius: 8px;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
		background: url("../images/arrow-right.svg") center no-repeat, #fff;
		transition: all 0.3s ease-in-out 0s;
		&:after {
			font-size: 0;
		}
		@media (any-hover: hover) {
			&:hover {
				transform: scale(0.97);
				box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
			}
		}
		@media (max-width: 500px) {
			top: 150px;
			right: -10px;
		}
	}
}
.mini-slider {
	margin: 0 -10px 20px -10px;
	overflow: hidden;
	@media (max-width: 992px) {
		margin: 0 -10px 20px -10px;
	}
	@media (max-width: 500px) {
		margin: 0 0 20px -10px;
	}
	&__wrapper {
		display: flex;
	}

	&__slide {
		padding: 0 10px;
		cursor: pointer;
		&.swiper-slide-thumb-active {
			.mini-slider__image {
				&:after {
					background: rgba(0, 0, 0, 0);
				}
			}
		}
		@media (any-hover: hover) {
			&:hover {
				.mini-slider__image {
					img {
						transform: scale3d(1.03, 1.03, 1.03);
					}
					&:after {
						background: rgba(0, 0, 0, 0.2);
					}
				}
			}
		}
	}

	&__image {
		border-radius: 8px;
		overflow: hidden;
		position: relative;
		padding: 0 0 60% 0;
		margin-bottom: 22px;
		img {
			object-fit: cover;
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			transition: transform 0.3s ease 0s;
			transform: scale3d(1, 1, 1);
		}
		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, 0.6);
			border-radius: 8px;
			transition: background-color 0.3s ease 0s;
		}
	}

	&__date {
		font-size: 11px;
		line-height: 130%;
		color: #212121;
		margin-bottom: 10px;
	}

	&__text {
		font-weight: 500;
		font-size: 14px;
		line-height: 150%;
		color: #212121;

		word-break: break-word;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
		height: 60px;
	}
}
.event {
	text-align: center;
	padding: 0 10px 15px 10px;
	border-bottom: 1px solid #e8e8e8;
	@media (max-width: 767px) {
		padding: 0 0 15px 0;
	}
	&:not(:last-child) {
		margin-bottom: 30px;
	}
	&__tournament {
		font-size: 14px;
		line-height: 130%;
		color: #c7c7c7;
		margin-bottom: 6px;
	}

	&__result {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 10px;
	}

	&__player {
		background: #ffffff;
		border: 1px solid #e7e7e7;
		border-radius: 50%;
		height: 58px;
		width: 58px;
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			width: 38px;
		}
	}

	&__score {
		background: #ededed;
		border-radius: 8px;
		font-weight: 500;
		font-size: 16px;
		line-height: 130%;
		color: #212121;
		padding: 4px 16px;
	}

	&__teams {
		font-weight: 500;
		font-size: 16px;
		line-height: 130%;
		color: #212121;
	}
}
